<template>
  <div class="content-main-top-container">
    <div class="banner-st">
      <div :style="bannerStyle">
        <div style="text-align: center">
          <div class="banner-desc1">내 서비스에 맞추어</div>
          <div class="banner-desc2">런치팩 상품 플랜을 선택하세요</div>
        </div>
        <!--<div>
          <div class="spot1-title">합리적인 가격으로 나만의 서비스를 런칭하세요</div>
          <div class="spot1-desc-wrapper">
            <div>
              <div class="spot1-desc">- 부가세 별도</div>
              <div class="spot1-desc"><span>- 지원 클라이언트 : PC웹(모바일 웹뷰) & 모바일웹 <span style="color:#a0a0a0;margin-left:8px">*APP 작업 비용 별도</span></span></div>
              <div class="spot1-desc">- 필수 선택사항 : 테마, 관리비, 호스팅비</div>
              <div class="spot1-desc">- 플러그인 추가 가능(기능 및 가격 별도 문의)</div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="banner-tip">
        *부가세 별도이며, 유지/보수 및 호스팅비 요금 플랜은 필수 선택사항입니다. <br>
        *앱 작업 및 플러그인 추가는 런치팩 고객센터에 문의해 주시기 바랍니다.
      </div>
    </div>
    <div>
      <!-- 테마 -->
      <div class="container">
        <div class="price-sub-title">테마 금액</div>
        <div class="list-wrapper">
          <div class="theme-card-wrapper" :class="`theme-card-${theme_idx+1}`" v-for="(theme, theme_idx) in theme_list" :key="`theme-${theme_idx}`">
            <div>
              <span class="theme-title title-15">{{ theme.theme }}</span>
              <span v-if="theme.pc" class="include-pc-st">PC 전용웹 포함</span></div>
            <div class="margin-top-24">
              <div class="inline-block-60">
                <div class="theme-text-gray">서비스</div>
                <div style="height:42px">
                  <div class="theme-content" v-for="(s, service_idx) in theme.service" :key="`service-${service_idx}`">{{ s }}</div>
                </div>
              </div>
              <div class="inline-block-40">
                <div class="theme-text-gray">주문방식</div>
                <div class="theme-content">{{ theme.order_conversion_type }}</div>
              </div>
              <div class="inline-block-60 margin-top-24">
                <a class="show-theme-btn unselect" :href="theme.link" target="_blank">테마 둘러보기</a>
              </div>
              <div class="inline-block-40 theme-price margin-top-24">{{ theme.price | currency }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 관리비 -->
<!--      <div>-->
<!--        <div class="price-sub-title">관리비 플랜 안내</div>-->
<!--        <div class="list-wrapper">-->
<!--          <div :class="`plan-card`" class="card-wrapper" v-for="(p, plan_idx) in plan_list" :key="`plan-${plan_idx}`">-->
<!--            <div class="theme-title">{{ p.name }} <span v-if="p.recm" class="recm-st">추천</span></div>-->
<!--            <div class="theme-content">{{ p.content }}</div>-->
<!--            &lt;!&ndash; <div style="font-size:13px;color:red;margin-top:-14px;text-align:right;height:20px"><span v-if="plan_idx===0">*초기 서비스 추천</span></div> &ndash;&gt;-->
<!--            <div class="theme-price margin-top-24" style="text-align:right;">{{ p.price | currency }}/월</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="divide-line"></div>-->
<!--      </div>-->
      <!-- 유지보수 요금 -->
      <div style="background-color:#f8f8f8;margin-top:44px">
        <div class="container" style="padding-bottom:44px">
          <div class="price-sub-title">유지/보수 요금</div>
          <div class="card-wrapper" style="width:100%;background-color: white;margin:0">
            <div>
              <span class="theme-title title-20">시간당 50,000 ~ 150,000원</span>
              <span class="include-pc-st"
                    v-for="(item, m_idx) in ['담당자별 비용 상이', '최소 충전금액 존재']"
                    :key="`maintenance${m_idx}`">{{ item }}</span>
            </div>
            <div class="maintenance-content-flex">
              <div class="maintenance-content1-wrapper margin-top-24">
                <div>-  오류, 버그 대응은 물론 기능 추가나 수정 모두 가능</div>
                <div style="margin-top:12px">-  매달 관리비를 납부하는 방식이 아닌 <br>
                  <span style="margin-left:8px"></span>
                  내 서비스에 필요한 경우에 한해서 비용 차감</div>
                <div style="margin-top:12px">-  시간당 평균 5~6만 원 수준</div>
              </div>
              <div class="maintenance-content2-wrapper">
                <div class="price-img-wrapper">
                  <img src="/static/img/price_img_01.png"/>
                  <div class="price-content">서비스 개설 후 <br>1개월내 무상 보수</div>
                </div>
                <div class="price-img-wrapper">
                  <img src="/static/img/price_img_02.png"/>
                  <div class="price-content">서버 오류 및 버그 해결</div>
                </div>
                <div class="price-img-wrapper">
                  <img src="/static/img/price_img_03.png"/>
                  <div class="price-content">추가 기능 개발</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 호스팅 -->
      <div class="container">
        <div class="price-sub-title">호스팅 비용 안내 ( AWS & CDN )</div>
        <div class="list-wrapper">
          <div class="theme-card-wrapper" v-for="(h, host_idx) in host_list" :key="`plan-${host_idx}`">
            <div>
              <span class="theme-title title-20">{{ h.name }}</span>
              <span class="include-pc-st"
                    v-for="(tag, tag_idx) in h.tag" :key="`tag${tag_idx}`">{{ tag }}</span>
            </div>
            <div class="theme-content margin-top-24">{{ h.content }}</div>
            <div class="theme-price margin-top-24" style="text-align: right">
              <span v-if="h.price>0">{{ h.price | currency }}/월</span>
              <span v-else>-</span>
            </div>
<!--            <div class="inline-block-44 host-th">RAM</div>-->
<!--            <div class="inline-block-54 host-th">트래픽</div>-->
<!--            <div class="inline-block-44 host-td">{{ h.ram }}</div>-->
<!--            <div class="inline-block-54 host-td">{{ h.traffic }}</div>-->
<!--            <div class="inline-block-44 host-th" style="margin-top:6px">SSD</div>-->
<!--            <div class="inline-block-54 host-th" style="margin-top:6px">도메인</div>-->
<!--            <div class="inline-block-44 host-td">{{ h.ssd }}</div>-->
<!--            <div class="inline-block-54 host-td">{{ h.domain }}</div>-->
<!--            <div class="theme-price margin-top-24" style="text-align:right;">{{ h.price | currency }}/월</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "Price",

    components: {

    },

    data() {
      return {
        theme_list: [
          {
            "theme": "로컬 전화 연결 테마",
            "service": ['위치기반 상품 검색 플랫폼', '위치기반 전문가 검색 플랫폼'],
            "order_conversion_type": "전화연결",
            "price": 3000000,
            "link": "https://democall.launchpack.co.kr"
          },
          {
            "theme": "로컬 전화 연결 테마",
            "service": ['위치기반 전문가 검색 플랫폼'],
            "order_conversion_type": "전화연결",
            "price": 6000000,
            "link": "https://localtheme.launchpack.co.kr",
            "pc": true
          },
          {
            "theme": "아웃링크 테마",
            "service": ['입점 브랜드 상품 링크 플랫폼'],
            "order_conversion_type": "아웃링크",
            "price": 3000000,
            "link": "https://demooutlink.launchpack.co.kr"
          },
          {
            "theme": "예약 결제 테마",
            "service": ['서비스 예약 결제 플랫폼', '상품 픽업 예약 결제 플랫폼'],
            "order_conversion_type": "예약결제",
            "price": 6800000,
            "link": "https://reservetheme.launchpack.co.kr"
          },
          {
            "theme": "숙박 서비스 예약 결제 테마",
            "service": ['숙박 예약 결제 플랫폼'],
            "order_conversion_type": "예약결제",
            "price": 7000000,
            "link": "https://staydemo.launchpack.co.kr"
          },
          {
            "theme": "커머스 테마",
            "service": ['상품 주문&배송 플랫폼'],
            "order_conversion_type": "상품결제",
            "price": 8000000,
            "tip": "*PC 전용 웹 페이지",
            "link": "https://partnercommerce.launchpack.co.kr",
            "pc": true
          },
          {
            "theme": "커머스 테마",
            "service": ['플랫폼 관리자의 상품 판매 테마'],
            "order_conversion_type": "상품결제",
            "price": 5000000,
            "tip": "*결합상품",
            "link": "https://commercedemo.launchpack.co.kr"
          }
        ],
        plan_list: [
          {
            "name": "Basic",
            "content": "운영 솔루션 관련 요구사항 및 버그사항 비정기적 업데이트 \n" +
            "서버에러&결제 에러사항 해결 요청시, 72시간 내 대응 \n" +
            "프론트엔드 에러사항 최대 10일 내 대응",
            "price": 150000,
            "recm": true
          },
          {
            "name": "Plus",
            "content": "운영 솔루션 관련 요구사항 및 버그사항 비정기적 업데이트 \n" +
            "서버에러&결제 에러사항 해결 요청시, 48시간 내 대응 \n" +
            "프론트엔드 에러사항 최대 5일 내 대응",
            "price": 300000
          },
          {
            "name": "Business",
             "content": "운영 솔루션 관련 요구사항 및 버그사항 비정기적 업데이트 \n" +
            "서버에러&결제 에러사항 해결 요청시, 24시간 내 대응 \n" +
            "프론트엔드 에러사항 최대 3일 내 대응",
            "price": 450000
          },
          {
            "name": "VIP",
            "content": "운영 솔루션 관련 요구사항 및 버그사항 비정기적 업데이트 \n" +
            "서버에러&결제 에러사항 해결 요청시, 24시간 내 대응 \n" +
            "프론트엔드 에러사항 최대 24시간 내 대응",
            "price": 550000
          }
        ],
        host_list: [
          {
            "name": "Basic",
            "tag": ["추천"],
            "content": "1초 당 동시 접속 수가 100미만인 경우",
            "price": 50000
          },
          {
            "name": "Business",
            "tag": [],
            "content": "1초 당 동시 접속 수가 100이상 ~ 500미만인 경우",
            "price": 90000
          },
          {
            "name": "독립호스팅",
            "tag": ["상담 후 비용 결정"],
            "content": "1초 당 동시 접속 수가 500미만인 경우",
            "price": 0
          }
        ]
      }
    },

    created() {

    },

    computed: {
      bannerStyle() {
        let style = {
          backgroundImage: 'url(/static/img/price_banner_beta.png)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPositionX: 'center',
          backgroundColor: 'white',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }
        return style
      }
    },
    methods: {

    }

  }
</script>

<style scoped>
  .banner-st {
    /*background-color: #eef1f3;*/
    /*padding-bottom: 78px;*/
    height: 400px;
    border-bottom: 1px solid #e6e6e6;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
  }
  .container {
    overflow-y: hidden;
  }
  .list-wrapper {
    margin : -6px -8px;
  }
  .divide-line {
    height: 1px;
    background-color: #ddd;
    margin: 77px 0;
  }
  .include-pc-st, .recm-st {
    color: #fd7b22;
    font-size: 12px;
    background-color: #f1f1f1;
    padding: 3px 6px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
  }
  .price-sub-title,
  .theme-content,
  .theme-title,
  .host-td {
    color: #5e5353;
  }
  .theme-text-gray,
  .host-th {
    color: #9b9b9b;
  }
  .theme-card-wrapper,
  .card-wrapper,
  .inline-block-54,
  .inline-block-60,
  .inline-block-44,
  .inline-block-40 {
    display: inline-block;
    vertical-align: top;
  }
  .theme-card-wrapper {
    width: 31%;
  }
  .card-wrapper {
    width: 35%;
  }
  .theme-card-wrapper,
  .card-wrapper {
    /* padding: 20px 15px 28px 15px; */
    padding: 16px;
    border-radius:4px;
    margin: 6px 8px;
    border: 1px solid #e2e2e2;
    box-shadow: 2px 2px 6px 1px #e2e2e2;
  }
  .inline-block-40 {
    width: 33%;
  }
  .inline-block-54 {
    width: 54%;
  }
  .inline-block-60 {
    width: 65%;
  }
  .inline-block-44 {
    width: 44%;
  }
  .spot1-title {
    font-size:40px;
    margin-top:80px;
    margin-bottom: 24px;
    color:#606060;
    text-align: center;
  }
  .spot1-desc-wrapper {
    display: flex;
    justify-content: center;

  }
  .spot1-desc-wrapper > div {
    background-color:white;
    border-radius:4px;
    width: 774px;
    padding: 32px;
  }
  .spot1-desc {
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #606060;
  }
  /* .spot1-desc > span {
    color: rgb(255, 102, 0);
    margin-right: 8px;
  } */
  .price-sub-title {
    font-size: 20px;
    margin-bottom: 22px;
    font-weight: bold;
    margin-top: 44px;
  }
  .show-theme-btn {
    background-color: rgb(255 130 47);
    color: white;
    padding: 4px 12px;
    font-size: 14px;
    border-radius: 4px;
  }
  .theme-card-5 {
    margin-right:33%;
  }
  .plan-card {
    white-space: pre-line;
  }
  .plan-card:nth-child(2n) {
    margin-right: 25%;
  }
  .theme-price {
    font-size: 18px;
  }
  .theme-content {
    font-size: 14px;
  }
  .theme-text-gray {
    font-size: 14px;
    font-weight: 300;
  }
  .margin-top-24 {
    margin-top: 24px;
  }
  .host-th,
  .host-td {
    font-size: 12px;
  }

  .banner-desc1,
  .banner-desc2 {
    font-size: 30px;
  }
  .banner-desc1 {
    font-weight: 500;
  }

  .banner-tip {
    position: absolute;
    left: 58%;
    bottom: 15%;
    color:#f77d05;
    font-size:13px;
    width:410px;
  }
  .maintenance-content-flex {
    display: flex;
  }

  .maintenance-content1-wrapper,
  .maintenance-content2-wrapper {
    width: 50%;
  }
  .maintenance-content2-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }

  .price-img-wrapper {
    text-align: center;
  }
  .price-img-wrapper > img {
    width: auto;
  }

  .theme-title {
    margin-right: 12px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 500;
  }
  .title-15 {
    font-size: 15px;
  }
  .title-20 {
    font-size: 20px;
  }

  .price-img-wrapper > .price-content {
    display: flex;
    align-items: center;
    height: 53px;
  }


  @media(min-width:1024px) and (max-width: 1440px){
    .theme-price {
      font-size: 16px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px) {
    .container {
      padding: 0 12px;
    }
    .theme-card-wrapper,
    .card-wrapper {
      width: 48%;
    }
    .theme-price {
      font-size: 16px;
    }
  }
  @media(max-width: 767px) {
    .container {
      padding: 0 12px;
    }
    .theme-card-wrapper,
    .card-wrapper {
      width: 100%;
      margin: 0;
      margin-bottom: 6px;
    }
    .theme-card-5,
    .plan-card:nth-child(2n-1) {
      margin-right: 0
    }
    /* .spot1-desc-wrapper {
      margin-left: 0
    } */
    .maintenance-content-flex {
      display: unset;
    }
    .maintenance-content1-wrapper,
    .maintenance-content2-wrapper {
      width: 100%;
    }
    .maintenance-content2-wrapper {
      margin-top: 24px;
    }
    .theme-price {
      font-size: 16px;
    }
  }
  @media(max-width:420px) {
    .spot1-title {
      font-size: 24px;
    }
    .spot1-desc {
      font-size: 13px;
    }
    .price-sub-title {
      margin: 35px 0;
    }
    .spot1-desc-wrapper > div {
      width: 100%;
      padding: 20px;
    }
    .list-wrapper {
      margin: 0
    }
    .banner-st {
      height: 250px
    }
    .content-main-top-container {
      padding-bottom: 70px;
    }
    .banner-desc1,
    .banner-desc2 {
      font-size: 20px;
    }
    .banner-tip {
      width: 100%;
      left: unset;
      bottom: 40px;
      text-align: center;
      font-size: 10px;
    }
    .maintenance-content-flex {
      display: unset;
    }
    .maintenance-content1-wrapper,
    .maintenance-content2-wrapper {
      width: 100%;
    }
    .maintenance-content2-wrapper {
      margin-top: 24px;
    }

    .price-img-wrapper > img {
      height: 50px
    }

    .price-img-wrapper > div {
      font-size: 11px;
    }
    .title-15 {
      font-size: 12px;
    }
    .title-20 {
      font-size: 16px;
    }
    .theme-price {
      font-size: 16px;
    }
  }

</style>
